import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

function Header(props) {
  const [show, setShow] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logout = () => {
    removeCookie('user')
  }
  useEffect(() => {
    if (props.name) {
      window.gtag("event", "page", {
        event_category: "page",
        event_label: props.name
      });
    }
  }, [])


  return (
    <>
      <div className="container header">
        <div className="row m-0">
          <div className="col-lg-3 col-10">
            <a href="/home">
              <img src={logo} alt="" style={{ width: "100%" }} />
            </a>
          </div>
          <div className="col-lg-9 col-2 d-flex justify-content-end align-items-center ">
            <div className="menu justify-content-end align-items-center d-md-flex d-none">

              {
                cookies.user ? <>
                  <NavLink to="/gameList">GAME LIST</NavLink>
                  <NavLink to="/addgame">ADD GAME</NavLink>
                </> : <>  <NavLink to="/">HOME</NavLink>
                  <span onClick={() => window.location.reload()} ><NavLink to="/aboutus">ABOUT US</NavLink></span>
                  <span onClick={() => window.location.reload()} ><NavLink to="/contactus">CONTACT US</NavLink></span>
                  <span onClick={() => window.location.reload()} ><NavLink to="/privacy-policy">PRIVACY-POLICY</NavLink></span></>
              }

            </div>
            {
              cookies.user && <div>
                <button onClick={logout} style={{ padding: '5px 15px' }}>Logout</button>
              </div>
            }
            <div
              className="d-flex d-md-none justify-content-end align-items-center h-100"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
              >
                <path
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose} className="bg-dark">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="menu justify-content-center align-items-center">
            <NavLink className="d-block mb-2" to="/">
              HOME
            </NavLink>
            <NavLink className="d-block mb-2" to="/aboutus">
              ABOUT
            </NavLink>
            <NavLink className="d-block mb-2" to="/contactus">
              CONTACT
            </NavLink>
            <NavLink className="d-block mb" to="/privacy-policy">
              PRIVACY
            </NavLink>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;
