import React, { useEffect } from "react";
import ViewMaster from "../../Components/ViewMaster/ViewMaster";

function About() {
  useEffect(() => {
    document.title = "Multi Game Zone | About Us"
  }, [])
  return (
    <>
      <div>
        <div className="container bgContent">
          <div className="py-4 text-white fs-18">
            <div className="mt-4">
              <h4 className="descHeading"> About Multi Game Zone </h4>
              <p>Multi Game Zone sounds like an exciting platform! It's great to hear that you're focused on creating a seamless gaming experience for players across various devices. The fact that your games are accessible on desktops, tablets, and mobile phones really emphasizes inclusivity and convenience for gamers everywhere.</p>

              <p>Offering a wide range of games that are free to play is a fantastic way to attract a diverse audience and ensure that everyone can enjoy gaming without any barriers. It's also wonderful that Multi Game Zone supports developers from all backgrounds, fostering creativity and innovation within the gaming community.</p>

              <p>The emphasis on instant access directly in web browsers makes gaming hassle-free and accessible on the go, which is perfect for today's fast-paced lifestyle. It sounds like Multi Game Zone truly aims to be the ultimate playground for gamers worldwide!</p>

              <p>We are sure our platform will continue to grow and provide endless entertainment for gamers of all ages and preferences. Here's to creating the best browser-based gaming platform in the world!</p>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMaster(About);
